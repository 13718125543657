import React, { useEffect, useState, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import { Helmet } from 'react-helmet';
const Cart = () => {
  const cartpopup = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [quantity, setQuantity] = useState(1);

  const [QuantityVerientid, setQuantityVerientid] = useState("");
  const [ProductidQuantity, setProductidQuantity] = useState("");

  const [QytSubTotals, setSubTotals] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [cartitems, setCartItem] = useState([]);
  const [citemid, setItemid] = useState([]);
  const [cartitemsAtt, setCartItemAtt] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(true);
  const [quantityvalidation, setQuantityValidation] = useState(0);
  const [varient, setvarient] = useState({});
  const [productids, setProductIds] = useState("");
  const [convertedArray, setConvertedArray] = useState([]);
  const [addtocartresponseMessage, setAddCartResponseMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState(null);
  const [totalPrices, setTotalPrices] = useState({});
  const [quantityValues, setQuantityValues] = useState({});
  const [productSKUDetails, setRelatedSKUDetails] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState(Array(20).fill(false)); // Assuming 5 rows
  const [product_de, setProduct_de] = useState(null);
  const [cartproductDetails, setCartProductDetails] = useState(null);

  const handleAddCartAlertClick = () => {
    setError(false);
  };
  const handleQuantityChange = (variantId, quantity, price, pro_id) => {
    varient[variantId] = parseInt(quantity);
    setTimeout(() => {
      setError("");
    }, 1000);
    setProductIds(pro_id);
    // console.log('proid:',pro_id);
    // console.log('varient detail:',variantId,quantity,varient);
    const newArray = Object.entries(varient)
      .filter(([variant_id, quantity]) => quantity > 0) // Only include entries with quantity > 0
      .map(([variant_id, quantity]) => ({
        variant_id,
        quantity: String(quantity),
      })); // Map to the desired format
    // console.log("convert to:",newArray);
    setConvertedArray(newArray);
    // console.log('v:',variantId);
    // console.log('p:',price);
    // console.log('q:',quantity);
    // setQuantityValidation(quantity);
    // if (quantity != '' && quantity.length > 1) {
    //   setError('');
    // }else{
    //   setError('Please Select Size and Add Quantity');
    // }
    // if (quantity !== 0) {
    //   setError('');
    // }
    setQuantityValues((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: parseInt(quantity, 10),
    }));
    setTotalPrices((prevTotalPrices) => ({
      ...prevTotalPrices,
      [variantId]: parseFloat(price) * parseInt(quantity, 10),
    }));
  };
  useEffect(() => {
    const initialTotalPrices = {};
    Object.keys(quantityValues).forEach((variantId) => {
      initialTotalPrices[variantId] =
        parseFloat(product_de.uprice) * quantityValues[variantId];
    });
    setTotalPrices(initialTotalPrices);
    const fetchCartsDe = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/carts`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setCartItem(response.data.data);
        setCartItemAtt(response.data.data.items);
        // setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== citemid) : []);
        // const updatedData = cartitems.items.filter(item => item.id !== citemid);
        // console.log('update d:',updatedData);
        // setCartItem(updatedData);
        // const updatedData1 =  cartitems.items(prevItems => Array.isArray(prevItems) ? prevItems.filter(item => item.id !== citemid) : []);
        // console.log('update d2:',updatedData1);
        // console.log('set item cart de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchCartsDe();
  }, []);

  const fetchData = async (query) => {
    if (searchTerm.trim() === "") {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/getAllProductsSKU?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );

      // console.log('all sku:',response?.data?.data);
      //  setSuggestions(response?.data?.data); // Assuming the API response is an array of results
      const filteredSuggestions = response?.data?.data.filter((suggestion) =>
        suggestion.sku.toLowerCase().includes(query.toLowerCase())
      );

      setSuggestions(filteredSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    fetchData(value);
  };

  const handleSuggestionClick = (selectedValue) => {
    setSearchTerm(selectedValue);
    setSuggestions([]); // Clear suggestions after selecting
  };
  const handleAlertClick = () => {
    setResponseMessage(false);
  };
  const [responseMessage, setResponseMessage] = useState("");
  const handleDeleteButtonClick = (id) => {
    setItemid(id);
    const collectdata = {
      product_id: id,
    };
    const fetchDeleteCartProduct = async (id) => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const response = await axios.post(
          `${apiUrl}/carts/destroy`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);
          //           const updatedData = cartitems.items.filter(item => item.id !== id);
          // console.log('update d1:',updatedData);
          // setCartItem(updatedData);
          // const updatedData1 =  cartitems.items(prevItems => Array.isArray(prevItems) ? prevItems.filter(item => item.id !== id) : []);
          // console.log('update d2:',updatedData1);
          setResponseMessage(response.data.message);
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          setTimeout(() => window.location.reload(true), 2000);
          // const updatedData = cartitems.filter(items => items.id !== id);
          // setCartItem(updatedData);

          // setTimeout(() => {
          //   // Code to execute after the initial timeout (e.g., window.location.reload())
          //   // window.location.reload();

          //   // Set up a periodic refresh using setInterval
          //   const refreshInterval = setInterval(() => {
          //     setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== citemid) : []);
          //     // Code to execute at regular intervals
          //     // For example, you can call another function here
          //     console.log('Interval action');
          //   }, 2000); // Refresh every 5 seconds (adjust as needed)

          //   // Optionally, you can clear the interval after a certain duration
          //   setTimeout(() => {
          //     setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== citemid) : []);
          //     clearInterval(refreshInterval);
          //   }, 20000); // Clear the interval after 30 seconds (adjust as needed)
          // }, 2000); // Initial timeout of 2 seconds
          // const refreshInterval = setInterval(() => {
          //   cartitems(); // Call fetchData at regular intervals
          // }, 1000); // Refresh every minute
          // const refreshInterval1 = setInterval(() => {
          //   cartitemsAtt(); // Call fetchData at regular intervals
          // }, 1000); // Refresh every minute
          // setTimeout(() => window.location.reload(), 2000);
          // Optionally, you can set up a timer or other triggers to refresh the data periodically

          // setTimeout(() => window.location.reload());
          // console.log('succeess',response.data.message);
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchDeleteCartProduct();
  };

  const handleBlur = () => {
    if (isValidQuantity()) {
      updateQuantityApiCall();
    } else {
      // Handle invalid quantity (display an error, prevent the API call, etc.)
      console.error("Invalid quantity entered");
    }
  };
  const updateQuantityApiCall = async () => {
   
    // console.log('update qyt:',quantity,QuantityVerientid,ProductidQuantity);
    const collectdata = {
      product_id: ProductidQuantity,
      variant_id: QuantityVerientid,
      quantity: quantity,
    };
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await axios.post(`${apiUrl}/carts/update`, collectdata, {
        headers: {
          Authorization: `Bearer ${localdata.data.token}`,
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      });
      if (response.data.success === true) {
        // Assuming you have a function like storeToken to handle token storage
        // storeToken(collectdata);

        // setCartItem(prevItems => Array.isArray(prevItems) ? prevItems.filter(item => item.id !== id) : []);
        setSubTotals(response.data.data.sub_total);
        setResponseMessage(response.data.message);
        toast.success(
          <div>
            <div>{response.data.message}</div>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 2000); // 7000 milliseconds is the autoClose duration
        // setTimeout(() => window.location.reload(), 2000);
        // setTimeout(() => window.location.reload());
        // console.log('succeess',response.data.message);
        // setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const handleQuantityInputChange = (event, veriant_id, pro_id) => {
  //   const value = parseInt(event);

  //   // Update local state only if it's a positive integer
  //   // if (!isNaN(value) && value >= 0) {
  //   //     setQuantity(value);
  //   // setQuantityVerientid(veriant_id);
  //   // setProductidQuantity(pro_id);
  //   // }else {
  //   //     setQuantity(1);

  //   //     setQuantityVerientid(veriant_id);
  //   //     setProductidQuantity(pro_id);
  //   // }

  //   if (isNaN(value) || value <= 0) {
  //     alert("Quantity must be greater than 0");
  //     // window.location.reload();
  //     return;
  //   } else {
  //     setQuantity(value);
  //     setQuantityVerientid(veriant_id);
  //     setProductidQuantity(pro_id);
  //   }
  // };
  const handleQuantityInputChange = (event, variant_id, pro_id, stock,isCommited) => {
    const value = parseInt(event);
    if (isNaN(value) || value <= 0) {
      alert("Quantity must be a positive integer greater than 0");
      return;
    }
    if (value > (stock-isCommited)) {
      alert("Quantity cannot exceed available stock is " + (stock-isCommited));
      setQuantity(stock-isCommited);
      setQuantityVerientid(variant_id);
      setProductidQuantity(pro_id);
      return;
    }
    else{
      setQuantity(value);
      setQuantityVerientid(variant_id);
      setProductidQuantity(pro_id);
    }
 
  };
  const isValidQuantity = () => {
    return !isNaN(quantity) && quantity >= 0;
  };
  const handleProDetails = (pro_name) => {
    nvg(`/product-detail/${pro_name}`);
    window.location.reload();
  };

  const handleAddCartButtonClick = (sku) => {
    if (cartpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(cartpopup.current);
      modal.show();
    }
    // setProductIds(id);
    // console.log('proid:',id);
    const fetchProductValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/productSearchBySKU?sku=${sku}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setProduct_de(response.data.data);
        setProductIds(response.data.data.id);
        setCartProductDetails(response.data.data.variant);
        setRelatedSKUDetails(response.data.data.related_sku);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchProductValue();
  };
  // Function to handle form submission
  // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
  // console.log('conv array submit:',convertedArray);

  const handleAddToCartSubmit = async (e) => {
    e.preventDefault();

    // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
    //   if(quantityvalidation <= 0){
    //   setError('Please Select Size and Add Quantity.');
    // } else {
    // Perform submit action or other logic here
    // console.log('quantity array:',quantityValues);
    // console.log('price array:',totalPrices);
    // console.log('Submit:', quantityvalidation);
    // }
    // const value = parseInt(quantityvalidation);

    // if (!isNaN(quantityvalidation) && quantityvalidation > 0 && quantityvalidation <= 1) {
    if (!convertedArray[0]) {
      // console.log('cons:',convertedArray);
      setError("Please Select Size and Add Quantity.");
    } else {
      // console.log('proids:',productids);
      // console.log('varient qyt array:',convertedArray);
      // Your submission logic here
      const collectdata = {
        product_id: productids,
        product_option: convertedArray,
      };
      // console.log('add to cart submit:',collectdata);

      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/carts/add`, collectdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });

        if (response.data.success === true) {
			
			
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setAddCartResponseMessage(response.data.message);

          setShowModal(false);
          toast.success(
            <div>
              <div>{response.data.message}</div>
              <button
                className="order-now"
                onClick={redirectToCart}
                style={{ marginTop: "10px" }}
              >
                Go to Cart
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            window.location.reload(true);
          }, 2000); // 7000 milliseconds is the autoClose duration
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const redirectToCart = () => {
    // Redirect logic to the cart page; replace with your actual redirect logic
    console.log("Redirecting to the cart page...");
    // For demonstration purposes, let's assume the cart page has a path '/cart'
    // You may want to use react-router-dom or your preferred routing library for this
    // window.location.href = '/cart';
    nvg(`/cart`);
  };

  return (
    <div style={{ overflow: "visible" }}>
      {loading ? <Loader /> : null}
      <ToastContainer />
      <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      <section className="cart-detail-sec inner-page-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="hedding-text d-flex justify-content-center">
                <h2 className="text-capitalize">My Shopping Cart</h2>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <div
              className={
                cartitemsAtt?.[0] ? "col-lg-8  col-sm-12 py-1" : "col-12"
              }
            >
              <div className="secondary">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control autocomplete-input"
                    placeholder="Search Products"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderRadius: "0px",
                      background: "#fbfbfb",
                      fontSize: "16px",
                      color: "#8f8f8f",
                      border: "1px solid #e5e5e5",
                    }}
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                  {searchTerm == "" ? (
                    <span className="input-group-text" id="basic-addon2">
                      <i
                        className="fa fa-search"
                        style={{
                          color: "#059fe2",
                          fontSize: "19px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  ) : (
                    <span
                      className="input-group-text"
                      onClick={() => handleAddCartButtonClick(searchTerm)}
                      id="basic-addon2"
                    >
                      <i
                        className="fa fa-search"
                        style={{
                          color: "#059fe2",
                          fontSize: "19px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  )}
                </div>
                {/* {loading && <p>Loading...</p>} */}
                {suggestions.length > 0 ? (
                  <ul
                    className="autocomplete-suggestions"
                    style={{ width: "839px !important", marginTop: "-16px" }}
                  >
                    {suggestions.map((suggestion) => (
                      <a
                        href="javascript:void(0);"
                        onClick={() => handleAddCartButtonClick(suggestion.sku)}
                      >
                        <li
                          key={suggestion.sku}
                          className="autocomplete-suggestion"
                          onClick={() => handleSuggestionClick(suggestion.sku)}
                        >
                          {suggestion.sku}&nbsp;-&nbsp;{suggestion.product_name}
                        </li>
                      </a>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
              {/* {responseMessage && (
        <div className="alert alert-success" onClick={handleAlertClick}>
          {responseMessage}
        </div>
      )} */}

              {cartitemsAtt?.[0] ? (
                cartitemsAtt.map((cart_info, index) => (
                  <div
                    className="row card-group product-block"
                    style={{
                      background: "white",
                      marginLeft: "1px",
                      marginRight: "2px",
                    }}
                    key={index}
                  >
                    <div className="col-3">
                      <div className="card imgcard">
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleProDetails(cart_info.slug)}
                        >
                          <div className="product-img">
                            {cart_info.thumbnail_image !== null ? (
                              <img
                                src={cart_info.thumbnail_image}
                                alt={cart_info.product_name}
                                style={{
                                  verticalAlign: "middle",
                                  maxWidth: "100%",
                                }}
                              />
                            ) : (
                              <img
                                src={cart_info.main_image}
                                alt={cart_info.product_name}
                                style={{
                                  verticalAlign: "middle",
                                  maxWidth: "100%",
                                }}
                              />
                            )}
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="card imgcard1">
                        <div className="product-details">
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleProDetails(cart_info.slug)}
                          >
                            <h2 className="pro-name">
                              {cart_info.product_name}
                            </h2></a>
                            <div
                              style={{
                                padding: "0px",
                                position: "relative",
                                left: "10px",
                              }}
                            >
                              {cart_info.product_categories.map((allCat) => (
                                <span
                                  set="Coverall without Reflective,Safety Clothing,Protective Coverall,mid plate
                            "
                                  className="pro-cat"
                                >
                                  {allCat.category_name},{" "}
                                </span>
                              ))}
                            </div>
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col">Size</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Cartons</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>

                              <tbody>
                                {cart_info.products_attributes.map(
                                  (variant_info, index) => (
                                    <tr key={index}>
                                      <td scope="row">
                                        {variant_info.item_code}
                                      </td>

                                      {variant_info.stock == "0" ? (
                                        <td className="qty">
                                          <input
                                            type="number"
                                            name={`quantity[${variant_info.variant_id}]`}
                                            placeholder="0"
                                            min="1"
                                            max={
                                              variant_info.stock
                                                ? variant_info.stock-variant_info.IsCommited
                                                : "0"
                                            }
                                            className="qtyIn disable_qyt"
                                            disabled
                                          />
                                        </td>
                                      ) : (
                                        <td className="qty">
                                          <input
                                            type="number"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              handleQuantityInputChange(
                                                e.target.value,
                                                variant_info.variant_id,
                                                cart_info.id,
                                                variant_info.stock,
                                                variant_info.IsCommited
                                              );
                                            }}
                                            name={`quantity[${variant_info.variant_id}]`}
                                            min="1"
                                            max={
                                              variant_info.stock
                                                ? variant_info.stock-variant_info.IsCommited
                                                : "0"
                                            }
                                            defaultValue={
                                              variant_info.quantity <=
                                              variant_info.stock
                                                ? variant_info.quantity
                                                : variant_info.stock
                                            }
                                          ></input>

                                          {/* <input type="number" onChange={handleQuantityInputChange} name={`quantity[${variant_info.variant_id}]`}   min="1" max={variant_info.stock ? variant_info.stock : '0'} value={variant_info.quantity}  /> */}
                                        </td>
                                      )}

                                      <td>
                                        {cart_info &&
                                        (
                                          cart_info.quantity /
                                          cart_info.U_SCartQty
                                        ).toFixed(2)
                                          ? (
                                              cart_info.quantity /
                                              cart_info.U_SCartQty
                                            ).toFixed(3)
                                          : "0.00"}
                                      </td>
                                      <td className="price">
                                        AED{" "}
                                        {cart_info.price
                                          ? cart_info.price
                                          : "0.00"}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            {/* <h3 className="pro-cat"><strong>{allCat.category_name ? allCat.category_name : ""}</strong></h3> */}
                          
                        </div>
                      </div>

              
                      {/* style={{ width: '100%', height: '205px' }} */}
              
                    </div>
                    {/* style={{ width: '100%', height: '205px' }} */}
                    <div
                      className="col-xl-3 col-3 p-xl-0 p-lg-0"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="row m-0"
                        style={{
                          position: "relative",
                          width: "100%",
                          background: "#f6f6f6",
                        }}
                      >
                        <div className="col-12 combo1">
                          <h4 className="title3451-2">PRICE</h4>
                          <h3 className="title098-2">
                            AED{" "}
                            {cart_info.subtotal ? cart_info.subtotal : "0.00"}
                          </h3>
                          <p className="delete_hover">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDeleteButtonClick(cart_info.id)
                              }
                              className=""
                            >
                              <i className="fa-solid fa-trash-can checkout_delete"></i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">Your shopping cart is empty.</div>
              )}
            </div>

            <div
              className="col-lg-4 col-sm-12 py-1 cart-right"
              style={{ position: "sticky", top: "10px" }}
            >
              {cartitemsAtt && cartitemsAtt?.length ? (
                <div>
                  <div className="payment-summary">
                    <div className="total-amount plr-20 bb-1">
                      <p>
                        {" "}
                        <strong
                          className="t-strong"
                          style={{ fontSize: "15px", fontWeight: "500" }}
                        >
                          {" "}
                          Products your shopping cart contains
                        </strong>
                        <strong className="blue-color">
                          {cartitemsAtt && cartitemsAtt?.length
                            ? cartitemsAtt?.length
                            : "0"}
                        </strong>
                      </p>
                    </div>
                    {/* {console.log("total carton:",cartitems.cartonsTotal)} */}
                    <div className="total-amount plr-20 bb-1">
                      <p>
                        {" "}
                        <strong
                          className="t-strong"
                          style={{ fontWeight: "500", color: "#000" }}
                        >
                          Total Number Of Cartons:
                        </strong>
                        <strong className="blue-color">
                          {" "}
                          {cartitems && cartitems.cartonsTotal
                            ? cartitems.cartonsTotal
                            : "0.00"}
                        </strong>
                      </p>
                    </div>
                  </div>

                  <div className="payment-summary">
                    <div className="header-part plr-20">
                      <h2 style={{ fontWeight: "500" }}>Payment Summary</h2>
                    </div>

                    <div className="total-amount plr-20">
                      <p>
                        Item Sub Total Amount:
                        <strong className="blue-color">
                          AED{" "}
                          {cartitems && cartitems.grandTotal
                            ? cartitems.grandTotal
                            : "0.00"}
                        </strong>
                      </p>
                    </div>

                    <div className="total-content plr-20">
                      <p>
                        Total Discount:
                        <strong className="green-color">
                          AED{" "}
                          {cartitems && cartitems.total_discount
                            ? cartitems.total_discount
                            : "0.00"}
                        </strong>
                      </p>
                      <p>
                        VAT ({cartitems && cartitems.vat ? cartitems.vat : "0%"}
                        ):
                        <strong className="color-black">
                          AED&nbsp;
                          {cartitems && cartitems.vat_amount
                            ? cartitems.vat_amount
                            : "0.00"}
                        </strong>
                      </p>
                    </div>
                    <div
                      className="footer-part plr-20"
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      <div className="amount-payable">
                        <p>
                          Amount Payable:{" "}
                          <strong>
                            AED{" "}
                            {cartitems && cartitems.amount_payable
                              ? cartitems.amount_payable.toFixed(2)
                              : "0.00"}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="proceed-to-check">
                    <a
                      href="/checkout"
                      className="text-uppercase  check-button"
                    >
                      Proceed To Checkout
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {/* add to cart model popup page start */}
      {showModal ? (
        <div
          className="modal fade"
          id="addToCartP"
          ref={cartpopup}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addToCartPLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label">
                        {" "}
                        <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong>
                      </h1>
                      <p className="text-center pb-2">
                        {" "}
                        {product_de && product_de.product_name
                          ? product_de.product_name
                          : ""}
                      </p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">
                            Item Group :{" "}
                            {product_de && product_de.sku ? product_de.sku : ""}{" "}
                          </li>
                          <li className="list-inline-item">
                            {" "}
                            Carton Qty :{" "}
                            {product_de && product_de.cartonQty
                              ? product_de.cartonQty
                              : "0"}{" "}
                          </li>
                        </ul>

                        {error && (
                          <div
                            className="alert alert-error"
                            onClick={handleAddCartAlertClick}
                          >
                            {error}
                          </div>
                        )}
                        <div className="row">
                          <div className="col-10">
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col"> Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartproductDetails?.[0]
                                  ? cartproductDetails.map(
                                      (variant_info, index) => (
                                        <tr
                                          id={`mp-size-${index}`}
                                          className={`outofstock${variant_info.stock}`}
                                          key={index}
                                        >
                                          <td scope="size">
                                            <div className="checkbox-col">
                                              <input
                                                type="checkbox"
                                                checked={checkboxStates[index]}
                                                onChange={() => {}}
                                                id={`size-v-${index}`}
                                                name={`quantity[${variant_info.id}]`}
                                                value={variant_info.id}
                                              />
                                              <label
                                                htmlFor={`size-v-${index}`}
                                                className="checkbox-label"
                                              >
                                                &nbsp;
                                                {typeof variant_info.item_code ===
                                                "string"
                                                  ? variant_info.item_code.split(
                                                      "-"
                                                    )[1]
                                                  : ""}
                                              </label>
                                            </div>
                                          </td>
                                          {variant_info.stock > 0 && product_de.uprice!="0.00" ? (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                id={
                                                  product_de &&
                                                  product_de.uprice
                                                    ? product_de.uprice
                                                    : "0.00"
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    variant_info.id,
                                                    e.target.value,
                                                    e.target.id,
                                                    product_de.id
                                                  )
                                                }
                                               
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder="0"
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock-variant_info.IsCommited
                                                    : "0"
                                                }
                                                className="qtyIn"
                                              />
                                              {/* <input type="number" id={product_de && product_de.uprice ? product_de.uprice : '0.00'} onChange={(e) => handleQuantityChange(variant_info.id, e.target.value)} name={`quantity[${variant_info.id}]`} defaultValue={0}  min="0" max={variant_info.stock ? variant_info.stock : '0'} className="qtyIn"  /> */}
                                              {/* setvarient[variant_info.id]= e.target.value} */}
                                            </td>
                                          ) : (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder="0"
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock-variant_info.IsCommited
                                                    : "0"
                                                }
                                                className="qtyIn disable_qyt"
                                                disabled
                                              />
                                            </td>
                                          )}
                                          <td className="label_addtocart_table">
                                            {" "}
                                            AED{" "}
                                            {product_de && product_de.uprice
                                              ? product_de.uprice
                                              : "0.00"}
                                          </td>
                                          <td className="label_addtocart_table">
                                            AED{" "}
                                            {totalPrices[variant_info.id] ||
                                              "0.00"}
                                          </td>
                                          {/* <td className="label_addtocart_table">AED {quantityupdatedprice && quantityupdatedprice ? quantityupdatedprice : '0.00'} </td> */}
                                          {/* updateallvalue */}
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr
                                        className="subtotal"
                                        key={sku_info.sku}
                                      >
                                        <td>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() => {
                                              nvg(
                                                "/product-detail/" +
                                                  product_de.slug
                                              );
                                            }}
                                            className=""
                                            style={{
                                              fontWeight: "400",
                                              color: "#007bff",
                                            }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3">
                        <button
                          type="submit"
                          className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2"
                        >
                          Confirm Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* End add to cart model popup */}

      <Footer />
    </div>
  );
};
export default Cart;
